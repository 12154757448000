@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .input {
        @apply rounded-lg border px-3 py-2 bg-white w-full;
    }
    .btn {
        @apply rounded-lg border px-3 py-2 bg-white w-full;
    }
    .table {
        @apply rounded-lg border p-3 bg-white w-screen md:w-full h-auto;
    }
    /*.table table {*/
    /*    @apply table-auto;*/
    /*}*/
    .table table tr * {
        @apply px-3 py-2 gap-2 border w-full whitespace-nowrap !important;
    }
}

.select__control {
    /*@apply mb-3;*/
    @apply transition duration-150 ease-in-out flex flex-wrap w-full px-3 py-[0.1rem] rounded-lg !important;
    @apply border border-gray-200 !important;
}
.select__value-container {
    @apply p-0 !important;
}
.select__placeholder {
    @apply text-gray-400 !important
}